import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@mui/material';
import { gridSpacing } from '../../../store/constant';

export const ToneSelect = ({ onChange, selected }) => {
  const personalityList = [
    'Formal',
    'Informal',
    'Optimistic',
    'Worried',
    'Friendly',
    'Curious',
    'Assertive',
    'Encouraging',
    'Surprised',
    'Cooperative',
  ];

  return (
    <Grid container direction="row" spacing={gridSpacing}>
      <Grid item xs={12}>
        <Autocomplete
          options={personalityList}
          disabled={!personalityList.length}
          fullWidth
          freeSolo={true}
          onInputChange={(event, value) => onChange(value)}
          onChange={(event, value) => onChange(value)}
          value={selected}
          renderInput={(params) => (
            <>
              <TextField {...params} placeholder={'Informal or Write like celebrity xyz'} label="Select or Type Tone" />
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

ToneSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};
