import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

// routes
import { AppRoutes, PublicRoutes } from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import 'react-dropzone-uploader/dist/styles.css';
import { AuthProvider } from './contexts/auth-context';

// ----------------------------------------------------------------------

export default function App() {
  const auth = useSelector((state) => state.auth);

  const loadPage = () => {
    if (auth.isLoading) {
      return <PublicRoutes />;
    } else {
      return <AppRoutes />;
    }
  };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <AuthProvider>{loadPage()}</AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
