import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import {
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

import { PersonalitySelect } from '../sections/@dashboard/persona-content/PersonalitySelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { FormikTextInput } from '../components/form/FormikTextInput';

export default function PersonaMessageWriterPage() {
  const theme = useTheme();

  const [output, setOutput] = useState('');

  const formik = useFormik({
    initialValues: {
      persona: '',
      persona_description: '',
      persona_job_titles: '',
      persona_industries: '',
    },
    validationSchema: Yup.object({
      persona: Yup.string().max(255).required('Persona is required'),
      persona_description: Yup.string().max(255).required('Description is required'),
      persona_job_titles: Yup.string().max(255).required('Job Titles is required'),
      persona_industries: Yup.string().max(255).required('Industries is required'),
    }),
    onSubmit: async (values, helpers) => {
      const resp = await CoreApi.writeMessageForPersona(values);

      if (resp?.success) {
        setOutput(resp.data.message);
      } else {
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  return (
    <>
      <Helmet>
        <title> User | Sella AI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Content Rewrite by Personality
          </Typography>
        </Stack>

        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} sx={{ mt: 1.5 }}>
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="persona" type={'text'} label={'Persona'} />
            </Grid>
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="persona_description" type={'text'} label={'Description'} />
            </Grid>
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="persona_job_titles" type={'text'} label={'Job Titles'} />
            </Grid>
            <Grid item xs={12}>
              <FormikTextInput
                formik={formik}
                name="persona_industries"
                type={'text'}
                label={'Industries'}
                placeholder={'test'}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                minRows={8}
                maxRows={8}
                fullWidth
                placeholder="Output"
                variant="outlined"
                value={output}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                type="submit"
                variant={'contained'}
              >
                Generate
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
