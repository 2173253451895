import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import {
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Button,
  CardContent,
  CardMedia,
  Card,
  CardActions,
  Box,
  IconButton,
} from '@mui/material';

import { PersonalitySelect } from '../sections/@dashboard/persona-content/PersonalitySelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { FormikTextInput } from '../components/form/FormikTextInput';
import * as PropTypes from 'prop-types';

function SkipNextIcon() {
  return null;
}

function SkipPreviousIcon() {
  return null;
}

function PlayArrowIcon(props) {
  return null;
}

PlayArrowIcon.propTypes = {
  sx: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
export default function PodcastSearch() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      q: '',
    },
    validationSchema: Yup.object({
      q: Yup.string().max(255).required('Search keyword is required'),
    }),
    onSubmit: async (values, helpers) => {
      console.log(values);
      const resp = await CoreApi.podcastSearch(values);

      if (resp?.success) {
        console.log(resp.data);
        setResults(resp.data.results);
      } else {
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  const generateTranscript = async (item) => {
    setLoading(true);
    await CoreApi.generateTranscript({
      file_name: item.id,
      type: 'audio',
      status: 'pending',
      origin_url: item.audio,
      image_url: item.image,
      thumbnail_url: item.thumbnail,
      file_external_id: item.id,
      title: item.title_original,
      description: item.description_original,
    });
    setLoading(false);
    window.open('/podcast/me', '_blank');
  };

  const getItemCard = (item) => {
    return (
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
              {item.title_original}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ height: 120, overflow: 'auto' }}
            >
              {item.description_original}
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            <LoadingButton onClick={() => window.open(item.audio, '_blank')}>Download Audio</LoadingButton>
            <LoadingButton onClick={() => window.open(item.link, '_blank')}>Source</LoadingButton>
            <LoadingButton loading={loading} onClick={() => generateTranscript(item)}>
              Generate Transcript
            </LoadingButton>
          </Box>
        </Box>
        <CardMedia component="img" sx={{ width: 240 }} image={item.image} alt={item.title_original} />
      </Card>
    );
  };

  const displayResults = () => {
    return results.map((r) => {
      return (
        <Grid key={r.id} item xs={12}>
          {getItemCard(r)}
        </Grid>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title> User | Sella AI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Search Podcast
          </Typography>
        </Stack>

        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} sx={{ mt: 1.5 }}>
            <Grid item xs={11} height={60}>
              <FormikTextInput formik={formik} name="q" type={'text'} label={'Search'} />
            </Grid>

            <Grid item xs={1} sx={{ height: 60 }}>
              <LoadingButton
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                type="submit"
                variant={'contained'}
                fullWidth
                size={'large'}
              >
                Search
              </LoadingButton>
            </Grid>

            {displayResults()}
          </Grid>
        </form>
      </Container>
    </>
  );
}
