import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Stack, Container, Typography, Grid, CardContent, CardMedia, Card, Box, Chip } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { FormikTextInput } from '../components/form/FormikTextInput';
import * as PropTypes from 'prop-types';
import { useEffect } from 'react';

function PlayArrowIcon(props) {
  return null;
}

PlayArrowIcon.propTypes = {
  sx: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
export default function Podcasts() {
  const [results, setResults] = useState([]);

  useEffect(() => {
    CoreApi.getPodcasts().then((podcasts) => {
      setResults(podcasts.data.results);
    });
  }, []);

  const getItemCard = (item) => {
    return (
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
              {item.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ height: 120, overflow: 'auto' }}
            >
              {item.description}
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            <Typography component="div" variant="subtitle1">
              Status: <Chip label={item.status.toUpperCase()} variant="outlined" />
            </Typography>
            <LoadingButton
              disabled={item.status.toUpperCase() != 'READY'}
              onClick={() => window.open(`/sellaWriter/?transcript=${item.id}`, '_self')}
            >
              Create Article
            </LoadingButton>
          </Box>
        </Box>
        <CardMedia component="img" sx={{ width: 240 }} image={item.image_url} alt={item.title_original} />
      </Card>
    );
  };

  const displayResults = () => {
    return results.map((r) => {
      return (
        <Grid key={r.id} item xs={12}>
          {getItemCard(r)}
        </Grid>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title> User | Sella AI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Podcasts
          </Typography>
        </Stack>

        <Grid container spacing={1} sx={{ mt: 1.5 }}>
          {displayResults()}
        </Grid>
      </Container>
    </>
  );
}
