import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useDropzone } from 'react-dropzone';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useCallback, useState } from 'react';
import { FormikTextInput } from '../components/form/FormikTextInput';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Box,
  CardContent,
  CardActionArea,
  FormHelperText,
  Paper,
  FormControl,
  Divider,
} from '@mui/material';

import { AppWidgetSummary } from '../sections/@dashboard/app';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';
import ReactMarkdown from 'react-markdown';
import { PersonalitySelect } from '../sections/@dashboard/persona-content/PersonalitySelect';
import { ToneSelect } from '../sections/@dashboard/persona-content/ToneSelect';
import { PodcastSelect } from '../components/podcast/PodcastSelect';
import { useEffect } from 'react';

const MarkdownRenderer = ({ markdownContent }) => (
  <>
    <ReactMarkdown>{markdownContent}</ReactMarkdown>
  </>
);

export default function SellaWriterPage() {
  const theme = useTheme();
  const [output, setOutput] = useState('...');
  const [initialTranscript, setInitialTranscript] = useState(0);

  const getInitial = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const transcript = urlParams.get('transcript');
    return transcript;
  };

  const formik = useFormik({
    initialValues: {
      files: [],
      topic: 'The AI transformation is happening - are you on board, or missing out? (How)',
      tone: '',
      audience_persona: 'CEO / CFO',
      author_personality: '',
      document_id: getInitial(),
      output_format: 'title, description, introduction, main content, conclusion',
      input_theme:
        'Overall messages to convey to audiences \n\n' +
        '- The challenge of rising software development costs\n' +
        '- Leveraging AI for cost reduction\n' +
        "- Ensuring cost reduction doesn't compromise output\n" +
        '- “We can help you reduce software development costs without sacrificing the quality or efficiency of your projects”\n' +
        '- Halve your team and keep the output or keep your team and double your output\n' +
        "- Use case: Tired, out of date software' that should be redeveloped or brought up to date, but the cost cant be justified.\n" +
        '- Use case: A company that operates a mobile app to support their customers, such as an electricity company, wants to reduce costs, without compromising quality and volume of output.\n' +
        "- Use case: A media company that wants to launch a new app showing short-form video, but doesn't want to invest too much until the business model is proven.\n" +
        '- Use case: A healthcare organisation aiming to lower software development costs for patient management systems while ensuring data privacy and regulatory compliance.\n' +
        '\n',
      input_statistics_query: 'average software development cost in Australia for small to medium size companies',
    },
    validationSchema: Yup.object({
      topic: Yup.string().max(255).required('Topic is required'),
      tone: Yup.string().max(255).required('Tone is required'),
      audience_persona: Yup.string().max(255).required('Input audience persona'),
      author_personality: Yup.string().max(255).required(`Select author's personality`),
      output_format: Yup.string().max(255).required('Output Format is required'),
      input_theme: Yup.string().required('Theme is required'),
    }),

    onSubmit: async (values, helpers) => {
      if (!values.document_id && !values.files?.length) {
        formik.setErrors({ document_id: 'Select or upload a transcript' });
        return;
      }
      const resp = await CoreApi.generateArticleFromTranscript(values);
      if (resp?.success) {
        setOutput(resp.data.output);
      } else {
        if (resp.errors.document_id) {
          resp.errors.document_id = 'Select or upload a transcript';
        }
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles]);
  }, []);

  const removeFile = (fileName) => {
    formik.setFieldValue(
      'files',
      formik.values.files.filter((file) => file.name !== fileName)
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/plain': ['.txt', '.text'] },
  });

  return (
    <>
      <Helmet>
        <title> Dashboard | Sella AI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Write Content from Transcript
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="topic" type={'topic'} label={'Topic'} />
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="audience_persona" type={'text'} label={'Audience Persona'} />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.tone && formik.errors.tone)}
                sx={{ ...theme.typography.customInput }}
              >
                <ToneSelect onChange={(value) => formik.setFieldValue('tone', value)} />
                {formik.touched.tone && formik.errors.tone && (
                  <FormHelperText error id="standard-weight-helper-text-personality">
                    {formik.errors.tone}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.author_personality && formik.errors.author_personality)}
                sx={{ ...theme.typography.customInput }}
              >
                <PersonalitySelect onChange={(value) => formik.setFieldValue('author_personality', value.id)} />
                {formik.touched.author_personality && formik.errors.author_personality && (
                  <FormHelperText error id="standard-weight-helper-text-personality">
                    {formik.errors.author_personality}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Divider />
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="output_format" type={'text'} label={'Output Format'} />
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput
                formik={formik}
                name="input_statistics_query"
                type={'text'}
                label={'Statistics to Add'}
              />
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput
                formik={formik}
                name="input_theme"
                type={'text'}
                label={'Theme'}
                multiline={true}
                rows={8}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant={'h5'}>Select Transcript</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.document_id && formik.errors.document_id)}
                sx={{ ...theme.typography.customInput }}
              >
                <PodcastSelect
                  onChange={(value) => formik.setFieldValue('document_id', value.id)}
                  initial={getInitial()}
                />
                {formik.touched.document_id && formik.errors.document_id && (
                  <FormHelperText error id="standard-weight-helper-text-transcript">
                    {formik.errors.document_id}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant={'h5'}>Or Upload Transcript</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardActionArea>
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: 1,
                      borderRadius: 1,
                      borderStyle: 'dashed',
                      pt: 1,
                      mt: 1,
                      minHeight: 128,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <input {...getInputProps()} />
                    <Typography>Drag and drop some files here, or click to select files</Typography>
                  </Box>
                  {Boolean(formik.touched.files && formik.errors.files) && (
                    <FormHelperText error id="standard-weight-helper-text-content">
                      {formik.errors.files}
                    </FormHelperText>
                  )}
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ justifyContent: 'end' }}>
              <Box sx={{ textAlign: 'right' }}>
                <LoadingButton
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  type="submit"
                  variant={'contained'}
                >
                  Generate
                </LoadingButton>
              </Box>
            </Grid>

            <Box width="100%" />
            <Grid item xs={12}>
              {formik.values.files.map((data, index) => {
                return (
                  <Grid key={index} item xs={3} sm={3} md={3} sx={{ m: 1 }}>
                    <AppWidgetSummary
                      title={data.name}
                      total={1723315}
                      color="warning"
                      icon={'ant-design:file-filled'}
                      fileName={data.name}
                      onDelete={removeFile}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box width="100%" />

            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Card>
                  <CardHeader title="Output" />
                  <CardContent sx={{ minHeight: '50vh' }}>
                    <MarkdownRenderer markdownContent={output} />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
