import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, CardMedia, CircularProgress, Grid, Typography } from '@mui/material';
import CoreApi from '../../api/CoreApi';
import { gridSpacing } from '../../store/constant';

export const ArticleSelect = ({ onChange, selected }) => {
  const [articleList, setArticleList] = useState([]);
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);

  const updateArticleList = async () => {
    setIsLoadingArticle(true);
    const articles = await CoreApi.getArticles();
    setArticleList(articles.data.results);
    setIsLoadingArticle(false);
  };

  useEffect(() => {
    updateArticleList().then(() => {});
  }, []);

  return (
    <Grid container direction="row" spacing={gridSpacing}>
      <Grid item xs={12}>
        <Autocomplete
          value={selected}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.suggested_title}
          options={articleList}
          disabled={!articleList.length}
          onChange={(event, value) => {
            onChange(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label={isLoadingArticle ? <CircularProgress size="1rem" /> : 'Select Article'} />
          )}
        />
      </Grid>
    </Grid>
  );
};

ArticleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  initial: PropTypes.object,
};
