export const AppRouting = {
  root: '/',
  user: '/user',
  login: '/login',
  register: '/register',
  privacyPolicy: 'privacy-policy',
  transcriptToContent: '/transcript-to-content',
  sellaWriter: '/sellaWriter',
  editArticle: '/article/edit',
  pdfToContent: '/pdf-to-content',
  personalityContentReWrite: '/personality-content',
  personaMessageWriter: '/persona-message-writer',
  podcastSearch: '/podcast/search',
  podcastUpload: '/podcast/upload',
  podcasts: '/podcast/me',
};

export const LANDING_PAGE = AppRouting.sellaWriter;

const PUBLIC_PATHS = [AppRouting.login, AppRouting.register, AppRouting.privacyPolicy, AppRouting.register];

export const redirectIfUnauthorized = () => {
  if (!PUBLIC_PATHS.includes(window.location.pathname)) {
    window.location.href = AppRouting.login;
  }
};
