import { Helmet } from 'react-helmet-async';

import { useState } from 'react';
import { FormikTextInput } from '../components/form/FormikTextInput';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Box,
  CardContent,
  FormHelperText,
  FormControl,
  Divider,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import { AppWidgetSummary } from '../sections/@dashboard/app';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';
import ReactMarkdown from 'react-markdown';
import { PersonalitySelect } from '../sections/@dashboard/persona-content/PersonalitySelect';
import { ToneSelect } from '../sections/@dashboard/persona-content/ToneSelect';
import { useEffect } from 'react';
import { ArticleSelect } from '../components/article/ArticleSelect';
import { SelectRenderer } from '../components/article/SelectRenderer';
import { RepurposeFormatSelect } from '../components/article/RepurposeFormatSelect';

const MarkdownRenderer = ({ markdownContent }) => (
  <>
    <ReactMarkdown>{markdownContent}</ReactMarkdown>
  </>
);

export default function EditArticlePage() {
  const theme = useTheme();
  const [output, setOutput] = useState('...');
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedPersonality, setSelectedPersonality] = useState(null);
  const [selectedTone, setSelectedTone] = useState(null);
  const [openEditArticle, setOpenEditArticle] = useState(false);

  const getInitial = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('article');
  };

  useEffect(() => {
    const articleId = getInitial();
    if (articleId) {
      CoreApi.getArticleById(articleId).then((article) => {
        onArticleSelect(article);
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      files: [],
      topic: '',
      tone: '',
      audience_persona: '',
      author_personality: '',
      reference_article: getInitial(),
      output_format: '',
      input_theme: '',
      input_statistics: '',
      input_statistics_query: '',
      repurpose_format: '',
      reference_article_content: '',
    },
    validationSchema: Yup.object({
      reference_article: Yup.string().max(255).required('Please select reference article'),
      topic: Yup.string().max(255).required('Topic is required'),
      tone: Yup.string().max(255).required('Tone is required'),
      audience_persona: Yup.string().max(255).required('Input audience persona'),
      author_personality: Yup.string().max(255).required(`Select author's personality`),
      output_format: Yup.string().max(255).required('Output Format is required'),
      repurpose_format: Yup.string().max(255).required('Repurpose Format is required'),
      input_theme: Yup.string().required('Theme is required'),
    }),

    onSubmit: async (values, helpers) => {
      console.log(values);
      const resp = await CoreApi.generateArticleFromReference(values);
      if (resp?.success) {
        setOutput(resp.data.output);
      } else {
        if (resp.errors.reference_article) {
          resp.errors.reference_article = 'Select Article to Edit/Repurpose';
        }
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  const onPersonalitySelect = (value) => {
    setSelectedPersonality(value);
    formik.setFieldValue('author_personality', value.id);
  };

  const onToneSelect = (value) => {
    setSelectedTone(value);
    formik.setFieldValue('tone', value);
  };

  const onArticleSelect = (value) => {
    setSelectedArticle(value);
    onPersonalitySelect(value.author_personality);
    onToneSelect(value.input_tone);
    formik.setFieldValue('reference_article', value.id);
    formik.setFieldValue('reference_article_content', value.output);
    formik.setFieldValue('topic', value.input_topic);
    formik.setFieldValue('audience_persona', value.audience_persona);
    formik.setFieldValue('output_format', value.output_format);
    formik.setFieldValue('input_theme', value.input_theme);
  };

  const getStatisticsOptions = () => {
    if (selectedArticle?.statistics?.length) {
      // formik.setFieldValue('input_statistics', selectedArticle.statistics[0].statistics);
      return selectedArticle?.statistics?.map((data, index) => {
        return (
          <Grid key={`stat-${data.id}`}>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {data.statistics}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      });
    } else {
      return (
        <Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  No statistics found
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Sella AI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Edit and Repurpose Article
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.reference_article && formik.errors.reference_article)}
                sx={{ ...theme.typography.customInput }}
              >
                <ArticleSelect onChange={onArticleSelect} selected={selectedArticle} />
                {formik.touched.reference_article && formik.errors.reference_article && (
                  <FormHelperText error id="standard-weight-helper-text-reference-article">
                    {formik.errors.reference_article}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth variant="outlined" onClick={() => setOpenEditArticle(true)}>
                Edit
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent sx={{ height: '20vh' }}>
                  <Typography variant="h5" component="div">
                    Preview
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '40vh' }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {formik.values.reference_article_content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="topic" type={'topic'} label={'Topic'} />
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="audience_persona" type={'text'} label={'Audience Persona'} />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.tone && formik.errors.tone)}
                sx={{ ...theme.typography.customInput }}
              >
                <ToneSelect selected={selectedTone} onChange={onToneSelect} />

                {formik.touched.tone && formik.errors.tone && (
                  <FormHelperText error id="standard-weight-helper-text-personality">
                    {formik.errors.tone}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.author_personality && formik.errors.author_personality)}
                sx={{ ...theme.typography.customInput }}
              >
                <PersonalitySelect onChange={onPersonalitySelect} selected={selectedPersonality} />

                {formik.touched.author_personality && formik.errors.author_personality && (
                  <FormHelperText error id="standard-weight-helper-text-personality">
                    {formik.errors.author_personality}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Divider />

            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.repurpose_format && formik.errors.repurpose_format)}
                sx={{ ...theme.typography.customInput }}
              >
                <RepurposeFormatSelect onChange={(value) => formik.setFieldValue('repurpose_format', value)} />
                {formik.touched.repurpose_format && formik.errors.repurpose_format && (
                  <FormHelperText error id="standard-weight-helper-text-repurpose">
                    {formik.errors.repurpose_format}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="output_format" type={'text'} label={'Output Outline'} />
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput
                formik={formik}
                name="input_statistics"
                type={'text'}
                label={'Statistics (Copy from Options Below)'}
                multiline={true}
                rows={8}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant={'h4'}>Statistics Options</Typography>
              {getStatisticsOptions()}
            </Grid>

            <Grid item xs={12}>
              <FormikTextInput
                formik={formik}
                name="input_theme"
                type={'text'}
                label={'Theme'}
                multiline={true}
                rows={8}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ justifyContent: 'end' }}>
              <Box sx={{ textAlign: 'right' }}>
                <LoadingButton
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  type="submit"
                  variant={'contained'}
                >
                  Generate
                </LoadingButton>
              </Box>
            </Grid>

            <Box width="100%" />
            <Grid item xs={12}>
              {formik.values.files.map((data, index) => {
                return (
                  <Grid key={index} item xs={3} sm={3} md={3} sx={{ m: 1 }}>
                    <AppWidgetSummary
                      title={data.name}
                      total={1723315}
                      color="warning"
                      icon={'ant-design:file-filled'}
                      fileName={data.name}
                      onDelete={removeFile}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box width="100%" />

            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Card>
                  <CardHeader title="Output" />
                  <CardContent sx={{ minHeight: '50vh' }}>
                    <MarkdownRenderer markdownContent={output} />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Dialog
            open={openEditArticle}
            onClose={() => setOpenEditArticle(false)}
            fullWidth={true}
            maxWidth={'xl'}
            sx={{ height: '100vh' }}
          >
            <DialogTitle>Edit Article Content</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mt: 1, height: '75vh' }}>
                <FormikTextInput
                  formik={formik}
                  name="reference_article_content"
                  type={'text'}
                  label={'Edit Article'}
                  multiline={true}
                  rows={30}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditArticle(false)}>Cancel</Button>
              <Button onClick={() => setOpenEditArticle(false)}>Done</Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
    </>
  );
}
