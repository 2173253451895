// component
import SvgColor from '../../../components/svg-color';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { AppRouting } from '../../../routes/routes';
import { FileUpload, Search } from '@mui/icons-material';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EditNoteIcon from '@mui/icons-material/EditNote';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Search Podcast',
    path: AppRouting.podcastSearch,
    icon: <Search />,
  },
  {
    title: 'Upload Podcast or Video Recording',
    path: AppRouting.podcastUpload,
    icon: <FileUpload />,
  },
  {
    title: 'My Podcasts',
    path: AppRouting.podcasts,
    icon: <RecordVoiceOverIcon />,
  },
  {
    title: 'Sella Writer',
    path: AppRouting.sellaWriter,
    icon: <PsychologyIcon />,
  },
  {
    title: 'Edit Article',
    path: AppRouting.editArticle,
    icon: <EditNoteIcon />,
  },
  // {
  //   title: 'Transcript to Content Writer',
  //   path: AppRouting.transcriptToContent,
  //   icon: <RecordVoiceOverIcon />,
  // },
  {
    title: 'PDF to Content Writer',
    path: AppRouting.pdfToContent,
    icon: <PictureAsPdfIcon />,
  },

  {
    title: 'Personality Content ReWriter',
    path: AppRouting.personalityContentReWrite,
    icon: icon('ic_user'),
  },
  {
    title: 'Persona Message Writer',
    path: AppRouting.personaMessageWriter,
    icon: <SettingsAccessibilityIcon />,
  },

  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
