import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { gridSpacing } from '../../store/constant';

export const RepurposeFormatSelect = ({ onChange, initial }) => {
  const personalityList = [
    'Article',
    'Linkedin Short Post',
    'Teaser',
    'Single Sentence Post',
    'Linkedin Post',
    'Snippet',
  ];

  return (
    <Grid container direction="row" spacing={gridSpacing}>
      <Grid item xs={12}>
        <Autocomplete
          options={personalityList}
          disabled={!personalityList.length}
          fullWidth
          freeSolo={true}
          onInputChange={(event, value) => onChange(value)}
          onChange={(event, value) => onChange(value)}
          defaultValue={initial}
          renderInput={(params) => (
            <>
              <TextField {...params} placeholder={'Linkedin Short Post'} label="Select or Type Repurpose Format" />
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

RepurposeFormatSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};
