import CoreApi from '../api/CoreApi';
import { profileActions } from '../store/reducers/profileReducer';
import store from '../store';
import { AppRouting, LANDING_PAGE } from '../routes/routes';

export const login = async (email, password) => {
  localStorage.removeItem('company');
  const resp = await CoreApi.login(email, password);
  if (resp['success']) {
    const profile = await CoreApi.getProfile();
    await store.dispatch(profileActions.updateProfile(profile.data));
    window.location.href = LANDING_PAGE;
  }

  return resp;
};

export const register = async (data) => {
  localStorage.removeItem('company');
  const resp = await CoreApi.register(data);
  if (resp['success']) {
    const profile = await CoreApi.getProfile();
    await store.dispatch(profileActions.updateProfile(profile.data));
    window.location.href = LANDING_PAGE;
  }

  return resp;
};
