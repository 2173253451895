import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { register } from '../../../utils/login';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const theme = useTheme();
  const [checked, setChecked] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: 'nic@sella.io',
      password: '',
      first_name: 'a',
      last_name: 'b',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().max(255).required('First Name is required'),
      last_name: Yup.string().max(255).required('Last Name is required'),
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
      confirmed_password: Yup.string().max(255).required('Confirm your password'),
    }),
    onSubmit: async (values, helpers) => {
      let resp;
      if (values.password === values.confirmed_password) {
        resp = await register(values);
      } else {
        resp = {
          success: false,
          errors: { confirmed_password: 'Passwords do not match' },
        };
      }

      if (!resp?.success) {
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  return (
    <>
      <Stack spacing={3}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <FormControl
            fullWidth
            error={Boolean(formik.touched.first_name && formik.errors.first_name)}
            sx={{ ...theme.typography.customInput, mb: 2 }}
          >
            <InputLabel htmlFor="outlined-adornment-email-login">First Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-first-name-login"
              type="text"
              value={formik.values.first_name}
              name="first_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label="First Name"
              inputProps={{}}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <FormHelperText error id="standard-weight-helper-text-first-name-login">
                {formik.errors.first_name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(formik.touched.last_name && formik.errors.last_name)}
            sx={{ ...theme.typography.customInput, mb: 2 }}
          >
            <InputLabel htmlFor="outlined-adornment-last-name-login">Last Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-last-name-login"
              type="text"
              value={formik.values.last_name}
              name="last_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label="Last Name"
              inputProps={{}}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <FormHelperText error id="standard-weight-helper-text-last-name-login">
                {formik.errors.last_name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(formik.touched.email && formik.errors.email)}
            sx={{ ...theme.typography.customInput, mb: 2 }}
          >
            <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email-login"
              type="email"
              value={formik.values.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label="Email Address / Username"
              inputProps={{}}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email-login">
                {formik.errors.email}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(formik.touched.password && formik.errors.password)}
            sx={{ ...theme.typography.customInput, mb: 2 }}
          >
            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-login"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              inputProps={{}}
            />
            {formik.touched.password && formik.errors.password && (
              <FormHelperText error id="standard-weight-helper-text-password-login">
                {formik.errors.password}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(formik.touched.confirmed_password && formik.errors.confirmed_password)}
            sx={{ ...theme.typography.customInput }}
          >
            <InputLabel htmlFor="outlined-adornment-confirm-password-login">Confirm Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-confirm-password-login"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.confirmed_password}
              name="confirmed_password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              inputProps={{}}
            />
            {formik.touched.confirmed_password && formik.errors.confirmed_password && (
              <FormHelperText error id="standard-weight-helper-text-confirm-password-login">
                {formik.errors.confirmed_password}
              </FormHelperText>
            )}
          </FormControl>

          {formik.errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Register
            </Button>
          </Box>
        </form>
      </Stack>
    </>
  );
}
