import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/authReducer';
import profile from './reducers/profileReducer';

export default configureStore({
  reducer: {
    auth,
    profile,
  },
});
