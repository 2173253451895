import { Navigate } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
import UserPage from '../pages/UserPage';
import Page404 from '../pages/Page404';
import PersonalityContentRewritePage from '../pages/PersonalityContentRewritePage';
import PersonaMessageWriterPage from '../pages/PersonaMessageWriterPage';
import PdfToContentPage from '../pages/PdfToContentPage';
import TranscriptToContentPage from '../pages/TranscriptToContentPage';
import SellaWriterPage from '../pages/SellaWriterPage';
import EditArticlePage from '../pages/EditArticlePage';

import { AppRouting, LANDING_PAGE } from './routes';
import PodcastSearch from '../pages/PodcastSearch';
import RegisterPage from '../pages/RegisterPage';
import Podcasts from '../pages/Podcasts';
import PodcastUploadPage from '../pages/PodcastUploadPage';

// ----------------------------------------------------------------------

const MainRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={LANDING_PAGE} />, index: true },
        { path: AppRouting.pdfToContent, element: <PdfToContentPage /> },
        { path: AppRouting.transcriptToContent, element: <TranscriptToContentPage /> },
        { path: AppRouting.sellaWriter, element: <SellaWriterPage /> },
        { path: AppRouting.editArticle, element: <EditArticlePage /> },
        { path: AppRouting.user, element: <UserPage /> },
        { path: AppRouting.personalityContentReWrite, element: <PersonalityContentRewritePage /> },
        { path: AppRouting.personaMessageWriter, element: <PersonaMessageWriterPage /> },
        { path: AppRouting.podcastSearch, element: <PodcastSearch /> },
        { path: AppRouting.podcastUpload, element: <PodcastUploadPage /> },
        { path: AppRouting.podcasts, element: <Podcasts /> },
        { path: AppRouting.register, element: <RegisterPage /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ],
};

export default MainRoutes;
