import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useDropzone } from 'react-dropzone';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useCallback, useState } from 'react';
import { FormikTextInput } from '../components/form/FormikTextInput';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Box,
  CardContent,
  CardActionArea,
  FormHelperText,
  Paper,
} from '@mui/material';

import { AppWidgetSummary } from '../sections/@dashboard/app';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';

export default function PodcastUploadPage() {
  const formik = useFormik({
    initialValues: {
      files: [],
      description: '',
      title: '',
    },
    validationSchema: Yup.object({
      files: Yup.array().required('An mp4/mp3 is required').min(1, 'At least one pdf is required'),
      title: Yup.string().max(255).required('Title is required'),
      description: Yup.string().max(255).required('Description is required'),
    }),
    onSubmit: async (values, helpers) => {
      const resp = await CoreApi.uploadPodcast(values);
      if (resp?.success) {
        window.open('/podcast/me');
      } else {
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles]);
  }, []);

  const removeFile = (fileName) => {
    formik.setFieldValue(
      'files',
      formik.values.files.filter((file) => file.name !== fileName)
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'audio/mp4': ['.mp4', '.mp3'] },
  });

  return (
    <>
      <Helmet>
        <title> Dashboard | Sella AI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4">Upload Your Podcast</Typography>
        <Typography variant="body">We will transcribe it for you</Typography>
        <Box sx={{ mb: 5 }} />
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="title" type={'title'} label={'Title'} />
            </Grid>
            <Grid item xs={12}>
              <FormikTextInput formik={formik} name="description" type={'description'} label={'Description'} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardActionArea>
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: 1,
                      borderRadius: 1,
                      borderStyle: 'dashed',
                      pt: 1,
                      mt: 1,
                      minHeight: 128,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <input {...getInputProps()} />
                    <Typography>Drag and drop some files here, or click to select files</Typography>
                  </Box>
                  {Boolean(formik.touched.files && formik.errors.files) && (
                    <FormHelperText error id="standard-weight-helper-text-content">
                      {formik.errors.files}
                    </FormHelperText>
                  )}
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ justifyContent: 'end' }}>
              <Box sx={{ textAlign: 'right' }}>
                <LoadingButton
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  type="submit"
                  variant={'contained'}
                >
                  Upload
                </LoadingButton>
              </Box>
            </Grid>

            <Box width="100%" />
            <Grid item xs={12}>
              {formik.values.files.map((data, index) => {
                return (
                  <Grid key={index} item xs={3} sm={3} md={3} sx={{ m: 1 }}>
                    <AppWidgetSummary
                      title={data.name}
                      total={1723315}
                      color="warning"
                      icon={'ant-design:file-filled'}
                      fileName={data.name}
                      onDelete={removeFile}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box width="100%" />
          </Grid>
        </form>
      </Container>
    </>
  );
}
