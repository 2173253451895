import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
  ],
};
export default AuthenticationRoutes;
