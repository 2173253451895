import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CoreApi from '../../../api/CoreApi';
import { Button, CircularProgress, Grid } from '@mui/material';
import { gridSpacing } from '../../../store/constant';

export const PersonalitySelect = ({ onChange, selected }) => {
  const [personalityList, setPersonalityList] = useState([]);
  const [isLoadingPersonalities, setIsLoadingPersonalities] = useState(false);

  const updatePersonalityList = async () => {
    setIsLoadingPersonalities(true);
    const personalities = await CoreApi.getPersonalities();
    setPersonalityList(personalities.data.results);
    setIsLoadingPersonalities(false);
  };

  useEffect(() => {
    updatePersonalityList().then();
  }, []);

  return (
    <Grid container direction="row" spacing={gridSpacing}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          value={selected}
          options={personalityList}
          disabled={!personalityList.length}
          onChange={(event, value) => {
            onChange(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label={isLoadingPersonalities ? <CircularProgress size="1rem" /> : 'Personality'} />
          )}
        />
      </Grid>
    </Grid>
  );
};

PersonalitySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
};
