import core_axios from './core_axios';

const insertCompanyData = (data) => {
  return { ...data, ...{ company: localStorage.getItem('company') } };
};

export default class CoreApi {
  static login = async (email, password) => {
    const response = await core_axios.post('/token', {
      email,
      password,
    });
    if (response.success) {
      localStorage.setItem('token', response.data.token);
    }

    return response;
  };

  static register = async (data) => {
    const response = await core_axios.post('/profile', data);
    if (response.success) {
      localStorage.setItem('token', response.data.token);
    }

    return response;
  };
  static getProfile = async () => {
    return await core_axios.get('/my-profile');
  };

  static getGithubLoginUrl = async () => {
    return await core_axios.get('/accounts/github/login/url');
  };

  static updateRepoConnection = async (repoConnectionId, uid) => {
    return await core_axios.put(`/repository-connection/${repoConnectionId}/`, { uid });
  };

  static getRepoConnections = async () => {
    return await core_axios.get(`/repository-connection/`);
  };

  static getRepositories = async (repoConnectionId) => {
    return await core_axios.get(`/repository-connection/${repoConnectionId}`);
  };

  static getBranches = async (repoConnectionId, repoName) => {
    return await core_axios.get(`/repository-connection/${repoConnectionId}/branches?repo_name=${repoName}`);
  };

  static convertContentByPersonality = async (data) => {
    return await core_axios.post(`/personality-rewrite-content`, data);
  };

  static podcastSearch = async (data) => {
    return await core_axios.post(`/document/podcast/search`, data);
  };

  static getPersonalities = async () => {
    const personalities = await core_axios.get('/personality?page_size=20');
    return personalities;
  };

  static getPodcasts = async () => {
    const podcasts = await core_axios.get('/document?page_size=20&type=audio');
    return podcasts;
  };

  static getArticles = async () => {
    const articles = await core_axios.get('/article?page_size=20');
    return articles;
  };

  static getArticleById = async (id) => {
    const article = await core_axios.get(`/article/${id}`);
    return article.data;
  };

  static generateTranscript = async (data) => {
    return await core_axios.post(`/document/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static writeMessageForPersona = async (data) => {
    return await core_axios.post(`/message`, data);
  };

  static generateArticleFromPdf = async (data) => {
    const formData = new FormData();
    for (const file of data.files) {
      formData.append('files', file);
    }
    formData.append('tone', data.tone);
    formData.append('topic', data.topic);

    return await core_axios.post(`/article/pdf/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static uploadPodcast = async (data) => {
    const formData = new FormData();
    formData.append('file', data.files[0]);
    formData.append('description', data.description);
    formData.append('type', 'audio');
    formData.append('status', 'pending');

    return await core_axios.post(`/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static generateArticleFromTranscript = async (data) => {
    const formData = new FormData();
    for (const file of data.files) {
      formData.append('files', file);
    }

    formData.append('output_format', data.output_format);
    formData.append('tone', data.tone);
    formData.append('topic', data.topic);
    formData.append('audience_persona', data.audience_persona);
    formData.append('author_personality', data.author_personality);
    if (data.document_id) {
      formData.append('document_id', data.document_id);
    }

    formData.append('input_statistics_query', data.input_statistics_query);
    formData.append('input_theme', data.input_theme);

    return await core_axios.post(`/article/transcript/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static generateArticleFromReference = async (data) => {
    const formData = new FormData();

    formData.append('reference_article', data.reference_article);
    formData.append('reference_article_content', data.reference_article_content);
    formData.append('repurpose_format', data.repurpose_format);
    formData.append('output_format', data.output_format);
    formData.append('tone', data.tone);
    formData.append('topic', data.topic);
    formData.append('audience_persona', data.audience_persona);
    formData.append('author_personality', data.author_personality);
    formData.append('input_statistics_query', data.input_statistics_query);
    formData.append('input_statistics', data.input_statistics);
    formData.append('input_theme', data.input_theme);

    return await core_axios.post(`/article/transcript/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static createProject = async (name, accountId, region) => {
    return await core_axios.post('/fleet-project', {
      name: name,
      account: accountId,
      region: region,
    });
  };

  static getCloudAccounts = async () => {
    return await core_axios.get('/fleet-account');
  };

  static createCloudAccount = async (alias, accountId, accessKeyId, secretAccessKey, provider = 'aws') => {
    return await core_axios.post('/fleet-account', {
      alias: alias,
      account_id: accountId,
      access_key_id: accessKeyId,
      secret_access_key: secretAccessKey,
    });
  };

  static createAccount = async (email, password, confirmed_password, company) => {
    return await core_axios.post('/profile', { email, password, confirmed_password, company });
  };
}
