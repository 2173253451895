import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import {
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

import { PersonalitySelect } from '../sections/@dashboard/persona-content/PersonalitySelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CoreApi from '../api/CoreApi';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

export default function PersonalityContentRewritePage() {
  const theme = useTheme();

  const [output, setOutput] = useState('');

  const formik = useFormik({
    initialValues: {
      original_content: '',
      personality: '',
    },
    validationSchema: Yup.object({
      original_content: Yup.string().max(255).required('Content is required'),
      personality: Yup.string().max(255).required('Select personality'),
    }),
    onSubmit: async (values, helpers) => {
      const resp = await CoreApi.convertContentByPersonality(values);

      if (resp?.success) {
        setOutput(resp.data.new_content);
      } else {
        formik.setErrors(resp.errors);
      }
      helpers.setSubmitting(false);
    },
  });

  return (
    <>
      <Helmet>
        <title> User | Sella AI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Content Rewrite by Personality
          </Typography>
        </Stack>

        <form noValidate onSubmit={formik.handleSubmit}>
          <FormControl
            fullWidth
            error={Boolean(formik.touched.personality && formik.errors.personality)}
            sx={{ ...theme.typography.customInput }}
          >
            <PersonalitySelect onChange={(value) => formik.setFieldValue('personality', value.id)} />
            {formik.touched.personality && formik.errors.personality && (
              <FormHelperText error id="standard-weight-helper-text-personality">
                {formik.errors.personality}
              </FormHelperText>
            )}
          </FormControl>

          <Grid container spacing={1} sx={{ mt: 1.5 }}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={Boolean(formik.touched.original_content && formik.errors.original_content)}
                sx={{ ...theme.typography.customInput }}
              >
                <OutlinedInput
                  multiline
                  minRows={16}
                  maxRows={16}
                  fullWidth
                  label="Input"
                  variant="outlined"
                  id={'content-input'}
                  type={'text'}
                  value={formik.values.original_content}
                  name={'original_content'}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  inputProps={{}}
                />
                {formik.touched.original_content && formik.errors.original_content && (
                  <FormHelperText error id="standard-weight-helper-text-content">
                    {formik.errors.original_content}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                multiline
                minRows={16}
                maxRows={16}
                fullWidth
                placeholder="Output"
                variant="outlined"
                value={output}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                type="submit"
                variant={'contained'}
              >
                Convert
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
