import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, CardMedia, CircularProgress, Grid, Typography } from '@mui/material';
import CoreApi from '../../api/CoreApi';
import { gridSpacing } from '../../store/constant';

export const PodcastSelect = ({ onChange, initial }) => {
  const [podcastList, setPodcastList] = useState([]);
  const [isLoadingPodcasts, setIsLoadingPodcasts] = useState(false);
  const [selected, setSelected] = useState(initial ? {} : null);

  const updatePodcastList = async () => {
    setIsLoadingPodcasts(true);

    const podcasts = await CoreApi.getPodcasts();
    let initialSelected;
    const res = podcasts.data.results.map((r) => {
      const item = { id: r.id, label: r.title, thumbnail: r.thumbnail_url, image: r.image_url };
      if (`${initial}` === `${r.id}`) {
        initialSelected = item;
        setSelected(item);
      }
      return item;
    });
    setPodcastList(res);
    setIsLoadingPodcasts(false);
    if (initialSelected) {
      setSelected(initialSelected);
      onChange(initialSelected);
    }
  };

  useEffect(() => {
    updatePodcastList().then(() => {});
  }, []);

  return (
    <Grid container direction="row" spacing={gridSpacing}>
      <Grid item xs={12}>
        <Autocomplete
          // getOptionLabel={(option) => (
          //   <>
          //     <CardMedia component="img" sx={{ width: 32 }} image={option.thumbnail} alt={option.label} />
          //     <Typography sx={{ ml: 2 }}>{option.label}</Typography>
          //   </>
          // )}
          value={selected}
          options={podcastList}
          disabled={!podcastList.length}
          onChange={(event, value) => {
            setSelected(value);
            onChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={isLoadingPodcasts ? <CircularProgress size="1rem" /> : 'Podcast Transcript'}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

PodcastSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  initial: PropTypes.number,
};
